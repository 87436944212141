
import Geolocator from '@/lib/geolocator'
import LoadingSpinner from '@/components/loading_spinner'
import PageNavbar from '@/components/page_navbar'

export default {
  components: { LoadingSpinner, PageNavbar }
  created: ->
    @$store.dispatch('agencies/fetch')

  computed: {
    agencies: -> @$store.state.agencies.all
    loading: -> @$store.state.agencies.loading
    suppliers: -> @$store.state.suppliers.all
    selected_supplier: -> @$store.getters['suppliers/current']
    dismissable: -> true
  }

  methods: {
    choose_supplier: (id) ->
      @$store.dispatch('suppliers/select', id)
      @show_select = false
      @$router.push('/') if @$router.currentRoute.path != '/'

    # fetch_agency_suppliers: (location) ->
    #   @$store.dispatch('suppliers/fetch_agency_suppliers', { agency_id: @current_agency_id, location: location })
    #   .then (response) =>
    #     @show_select = true
    #     if @supplier_required
    #       if @suppliers.length == 1
    #         @$store.dispatch('suppliers/select', @suppliers[0].id)
    #       else @show_select = true
    #   .catch -> console.error

    # fetch_suppliers_for_location: ->
    #   new Geolocator(@fetch_agency_suppliers, @fetch_agency_suppliers).locate()

    formatted_distance: (number) ->
      number?.toFixed(1)

    show_supplier_selector: (id) ->
      @current_agency_id = id
      @$store.commit('suppliers/set_all', @$store.getters['agencies/get_suppliers'](id))
      @show_select = true
  }

  data: -> {
    current_agency_id: null
    show_select: false
  }
}
